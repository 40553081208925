<template>
  <div class="card">
    <DataTable
      :value="compLeaderboard"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      v-model:filters="filters"
      filterDisplay="row"
      responsiveLayout="scroll"
      :globalFilterFields="['team_name']"
    >
      <template #empty>No scores found.</template>
      <Column field="team_name" header="Name" style="min-width: 12rem">
        <template #body="{ data }">
          <span class="team-name">{{ data.team_name }}</span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            v-tooltip.top.focus="'Hit enter key to filter'"
          />
        </template>
      </Column>
      <Column header="Rank" style="min-width: 6rem">
        <template #body="{ data }">
          <div :class="formatRank(data.rank, data.rank_last)">
            <i :class="'updown-icon ' + iconRank(data.rank, data.rank_last)"></i
            >{{ data.rank }}
          </div>
        </template>
      </Column>
      <Column header="Trend" style="min-width: 12rem">
        <template #body="{ data }">
          <div :class="formatTrend(data.score_trend)">
            <i :class="'updown-icon ' + iconTrend(data.score_trend)"></i>
            {{ data.score_trend }}
          </div>
        </template>
      </Column>
      <Column header="Score" style="min-width: 12rem">
        <template #body="{ data }">
          <span :class="'score ' + formatScore(data.score)">{{ data.score }}</span>
        </template>
      </Column>
      <Column header="Completion" style="min-width: 12rem">
        <template #body="{ data }">
          <ProgressBar :value="data.completion" :showValue="false" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { FilterMatchMode } from "primevue/api";

export default {
  name: "Leaderboard",

  data() {
    return {
      filters: {
        team_name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["compLeaderboard"]),
  },
  created() {
    console.log("Leaderboard created");
    this.$store.dispatch("getLeaderBoard");
  },
  mounted() {
    this.$store.dispatch("startUpdateCompLeaderboardState");
  },
  beforeUnmount() {
    this.$store.dispatch("stopUpdateCompLeaderboardState");
  },

  methods: {
    ...mapActions(["openLeaderboard"]),

    formatRank(latest, last) {
      if (latest > last) {
        return "table-content-common up-glass score";
      } else if (latest < last) {
        return "table-content-common down-glass score";
      } else {
        return "table-content-common same score";
      }
    },
    iconRank(latest, last) {
      if (latest > last) {
        return "pi pi-angle-double-up";
      } else if (latest < last) {
        return "pi pi-angle-double-down";
      } else {
        return "pi pi-angle-double-right";
      }
    },
    formatTrend(trend) {
      if (trend > 0) {
        return "table-content-common up-glass score";
      } else if (trend < 0) {
        return "table-content-common down-glass score";
      } else {
        return "table-content-common unchanged-glass score";
      }
    },
    iconTrend(trend) {
      if (trend > 0) {
        return "pi pi-angle-double-up";
      } else if (trend < 0) {
        return "pi pi-angle-double-down";
      } else {
        return "pi pi-angle-double-right";
      }
    },

    formatScore(score) {
      if (score < 0) {
        return "negative";
      } else {
        return "positive";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

::v-deep(.p-progressbar) {
  height: 0.75rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #607d8b;
  }
}

::v-deep(.p-datepicker) {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

.rank {
  background-color: #607d8b;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}

.table-content-common {
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  padding: 0.2rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;

  display: flex;
  align-items: center;
}
.down-glass {
  background: rgba(244, 67, 54, 0.5);
}

.up-glass {
  background: rgba(76, 175, 80, 0.5);
}

.unchanged-glass {
  background: rgba(96, 125, 139, 0.5);
}

.updown-icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  vertical-align: middle;
}

.negative {
  color: rgba(244, 67, 54, 0.5);
}
.positive {
  color: #ffffff;
}

.score {
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;
  font-size: 28px !important;
  line-height: 38px !important;
  letter-spacing: -0.03em !important;
  font-weight: 800 !important;
}

.team-name {
  font-family: "Alliance1", "Alliance1HeaderFallback", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-feature-settings: unset;
  font-size: 28px !important;
  line-height: 38px !important;
  letter-spacing: -0.03em !important;
  font-weight: 800 !important;
  display: block;

  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #ffffff;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}
</style>
